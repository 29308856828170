$(function() {
  
    let $container = $(".scroll-pagination");

    let $loader = $container.find(".loader");
    let $content = $container.find(".scroll-content");
    let $pageInput = $container.find("input[name=page]");
    let $lastPageInput = $container.find("input[name=last_page]");

    let actionURL = $container.find("input[name=action]").val();
    
    let lastPage = +$lastPageInput.val();
    $loader.hide();

    let loadContent = function(page) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.append('page', page);

        let params = Array.from(searchParams.entries()).reduce(function(params, item) {
            let key = item[0];
            let value = item[1];
            params[key] = value;

            return params;
        }, {});
        
        $loader.show();

        axios.get(actionURL, { params: params })
        .then(function(response) {
            if (!response.data) return;
            $content.append(response.data);
            $pageInput.val(page);
        })
        .catch(function(error) {
            console.error('[Scroll Pagination] Error occured', error);
        })
        .then(function() {
            $loader.hide();
        });
    };


    $(window).on('scroll', function() {
    
        let currentPage = +$pageInput.val();
        if (currentPage === lastPage) return;
       
        let isLoading = $loader.is(":visible");
        if (isLoading) return;

        let scrollThreshold = document.documentElement.offsetHeight;
        let $footer = $('footer');
        if ($footer.length) {
            scrollThreshold -= $footer.outerHeight();
        }

        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > scrollThreshold;
        if (!bottomOfWindow) return;

        if(!$container.length) return;
        loadContent(currentPage + 1);
    });
});