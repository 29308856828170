$(document).on('submit', '.dynamic-form', function (e) {
    e.preventDefault();

    let $target = $(this);
    let form = $target.get(0);
    let formData = new FormData(form);

    formData.delete('dynamic-form');

    let actionURL = form.action;

    let submitForm = function () {

        axios.post(actionURL, formData, {
                responseType: 'json',
                headers: {
                    'Accept': 'application/json'
                }
            })
            .then(function (response) {
                let msg = response.data && response.data.message;
                if (msg) {
                    Swal.fire({
                        icon: "success",
                        text: msg,
                        confirmButtonText: `OK`,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    }).then((result) => {

                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                } else {
                    window.location.reload();
                }


            })
            .catch(function (error) {
                let response = error.response;
                let html = [];


                if (response.status === 422) {
                    let fields = response.data.errors;
                    let fieldMap = Object.entries(fields);

                    for (const [key, value] of fieldMap) {
                        html.push(value[0]);

                    }
                } else {
                    console.error('[Dynamic Form] Error', error);
                    html.push(response.data.message);
                }

                Swal.fire({
                    icon: "error",
                    html: html.join("<br />"),
                    timer: 3000,
                    timerProgressBar: true,

                });
            });
    };

    submitForm();
});
