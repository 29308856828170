require("./bootstrap");
import { gsap } from "gsap";

import {
    CSSPlugin,
    DrawSVGPlugin,
    MorphSVGPlugin,
    TimelineLite,
    TimelineMax,
    TweenMax
} from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { has } from "lodash";
gsap.registerPlugin(
    ScrollTrigger,
    MorphSVGPlugin,
    TweenMax,
    TimelineMax,
    TimelineLite,
    DrawSVGPlugin,
    CSSPlugin
);

$(() => {
    // Cookies
    let $cookiesPopup = $(".cookies-gdpr");
    if ($cookiesPopup.length) {
        let gdprConsent = localStorage.getItem("gdpr");
        if (!gdprConsent) {
            $(".cookies-gdpr button.accept").on("click", function() {
                localStorage.setItem("gdpr", true);
                $cookiesPopup.slideDown("slow", function() {
                    $cookiesPopup.remove();
                });
            });
            $(".cookies-gdpr button.decline").on("click", function() {
                $cookiesPopup.slideDown("slow", function() {
                    $cookiesPopup.remove();
                });
            });
        } else {
            $cookiesPopup.remove();
        }
    }
    $(".loader-bg").slideUp("slow");
    $("body").removeClass("overflow-hidden");
    if ($(".hero").length) {
        if ($(window).width() > 768) {
            let headerHeight = $("header.fixed-top").outerHeight();
            $(".hero").css("padding-top", headerHeight + 50);
        }
        if ($(window).width() < 768) {
            $("#heroBackground-svg").removeAttr("preserveAspectRatio");
        }
    }
    initSlider();
    let horizontalScrolls = $(".m-hscroll");
    horizontalScrolls.each(function() {
        let $el = $(this);
        let $child = $el.children().first();
        let scrollLeft = $el.width() / 2 - $child.width() / 2.5;
        $(this).scrollLeft(scrollLeft);
    });

    // Check if user is loged in
    // if ($("body").hasClass("logged-in")) {
    //     $("header .toolbar ul li a.login-btn").remove();
    // } else {
    //     $("header .toolbar ul li a.username").remove();
    //     $(".notification-trigger").remove();
    // }
    if ($("header .toolbar ul li a.login-btn").length) {
        $("header .toolbar ul li a.login-btn, .mobile-wrapper a.login-btn").on(
            "click",
            function(e) {
                e.preventDefault();
                $("#loginModal").modal("show");
                $(".modal-backdrop.show").css("z-index", 99999);
                $(".modal").css("z-index", 999999);
            }
        );
    }

    let path = window.location.href;
    let hash = window.location.hash;

    $(".about-tabs a").each(function() {
        $(this).removeClass("active");
        if (this.href === path) {
            $(this).addClass("active");
        }
    });
    $(".tab-content .tab-pane").each(function() {
        $(this).removeClass("active");
        if (this.id === hash.replace("#", "")) {
            $(this).addClass("active");
        }
    });
    let hasBreadcrumbs = document.getElementsByClassName("breadcrumbs");
    if (hasBreadcrumbs.length > 0) {
        let backUrl = $(".breadcrumbs")
            .find(".color-red")
            .prev()
            .find("a")
            .attr("href");
        $(".back-btn").prop("href", backUrl);
    }

    // Open Menu
    $(".menu-trigger, #open-menu").on("click", function() {
        $("#menu-popup").slideDown("slow");
        $("body").addClass("overflow-hidden");
    });
    //Mob menu
    $("#open-menu").on("click", () => {
        $("#menu-popup").slideDown();
    });
    $("#close-menu").on("click", function() {
        tl2.reverse();
        $("#menu-popup").slideUp("slow");
        $("body").removeClass("overflow-hidden");

        if ($(window).width() > 767) {
            $(".fixed-locator").fadeIn();
        }
    });

    // slick-prev slick-arrow
    var prevArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 83 83">
    <g id="prevSlide" transform="translate(381 -349)">
      <circle id="Ellipse_38" data-name="Ellipse 38" cx="41.5" cy="41.5" r="41.5" transform="translate(-381 349)" fill="#f50c1a"/>
      <path id="Union_25" data-name="Union 25" d="M.73,17.5,0,18.239.73,17.5,0,16.761l.73.739L18,0,.73,17.5,18,35Z" transform="translate(-348.5 372.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
    </g>
    </svg>`;
    var nextArrow = `<svg id="nextSlide" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 83 83">
    <circle id="Ellipse_38" data-name="Ellipse 38" cx="41.5" cy="41.5" r="41.5" fill="#f50c1a"/>
    <path id="Union_25" data-name="Union 25" d="M.73,17.5,0,16.761l.73.739L0,18.239.73,17.5,18,35,.73,17.5,18,0Z" transform="translate(50.5 58.5) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
  </svg>
  
`;
    $(".slick-prev").replaceWith(
        "<a " +
            "class=" +
            '"' +
            "slick-prev slick-arrow" +
            '">' +
            prevArrow +
            "</a>"
    );
    $(".slick-next").replaceWith(
        "<a " +
            "class=" +
            '"' +
            "slick-next slick-arrow" +
            '">' +
            nextArrow +
            "</a>"
    );
    $(".slick-prev").on("click", function(e) {
        e.preventDefault();
        $(".slick-slider").slick("slickPrev");
    });
    $(".slick-next").on("click", function(e) {
        e.preventDefault();
        $(".slick-slider").slick("slickNext");
    });

    // Set favorite recipe
    $(".setFavorite").on("click", function(e) {
        e.preventDefault();
        let recipeId = $(this).attr("data-recipe");
        let favorite = +$(this).attr("data-favorite");
        favorite = !favorite;

        if (!$("body").data("authenticated")) {
            $('[data-action="login"]')
                .first()
                .trigger("click");
        } else {
            axios
                .post(`/api/recipe-favorite`, {
                    id: recipeId,
                    favorite
                })
                .then(response => {
                    if ($("div.favourites").length) {
                        if (favorite == false) {
                            $(this)
                                .parent()
                                .parent()
                                .remove();
                        }
                    }
                    $(this).attr("data-favorite", +favorite);
                    $(this)
                        .find("i")
                        .toggleClass("fas");
                });
        }
    });
    // New question
    $(".newQuestion").on("click", function(e) {
        e.preventDefault();
        if (!$("body").data("authenticated")) {
            $('[data-action="login"]')
                .first()
                .trigger("click");
        } else {
            // window.location.href = window.location.origin + "/profile";
            $(this).hide();
            $(".side-cta").addClass("form-opened");
            $(".side-cta form").slideDown();
        }
    });

    // $(".m-slider .slick-arrow").hide();

    // Hide mobile Locator
    $(".mobile-locator .close-locator").on("click", function(e) {
        e.preventDefault();
        $(".mobile-locator").slideUp("slow");
        // $(".mobile-locator-trigger").slideDown();
    });
    $(".mobile-locator-trigger").hide(); //hiden by default if you dont want it

    // Show mobile locator arrow
    // $(".mobile-locator-trigger").on("click", function (e) {
    //     e.preventDefault();
    //     $(".mobile-locator").slideDown("slow");
    //     $(".mobile-locator-trigger").slideUp("slow");
    // });

    $(" .notification-d a.nav-link").on("click", function(e) {
        e.preventDefault();
        $(".notifications-wrapper").slideToggle();
    });
    // Identita page open default tab
    if (window.location.pathname == "/identita" && window.location.hash == "") {
        $('.about-tabs li a[href$="essence"]').addClass("active");
        $("#essence.tab-pane").addClass("active");
    }
    // Move elements on mobile
    if ($(window).width() < 769) {
        //Promo cta
        if ($("#prom-cta").length) {
            let getCtaContainer = $("#promo-cta");
            let addAfterContainer = $(".cat-cards");
            $(getCtaContainer).insertAfter(addAfterContainer);
        }
        if ($(".mobileFilters").length) {
            $(".remove-mob").remove();
            $(".mobileFilters").on("click", function() {
                $(this).toggleClass("opened");
                $(".filters-wrapper-mob").slideToggle();
            });
        }
        if ($(".tab-content").length) {
            let getMovableEl = $(".essence-tab .left-img .img-wrapper");
            let getMovableEl2 = $(".essence-tab .right-img .img-wrapper");
            let getMovableEl3 = $(".technique-tab .left-img .img-wrapper");
            let getMovableEl4 = $(".technique-tab .right-img .img-wrapper");
            let addAfterEl = $(
                ".tab-content .essence-tab .text-content p:first-of-type"
            );
            let addAfterEl2 = $(
                ".tab-content .essence-tab .text-content p:nth-of-type(2)"
            );
            let addAfterEl3 = $(
                ".tab-content .technique-tab .text-content p:first-of-type"
            );
            let addAfterEl4 = $(
                ".tab-content .technique-tab .text-content p:nth-of-type(2)"
            );
            $(getMovableEl).insertAfter(addAfterEl);
            $(getMovableEl2).insertAfter(addAfterEl2);
            $(getMovableEl3).insertAfter(addAfterEl3);
            $(getMovableEl4).insertAfter(addAfterEl4);
        }
    }
    if ($(window).width() > 768) {
        if ($(".mobileFilters").length) {
            $(".mob-filters").remove();
        }
    }

    // Gsap Animations Start
    // gsap.registerPlugin(ScrollTrigger);
    // Paralax
    let startShape = $("#Path_26729");
    let masterDuration = 0.5;
    let tl1 = new TimelineMax(); //Morph Svg
    let tl2 = new TimelineMax(); //Morph Svg
    tl1.to(startShape, {
        duration: masterDuration,
        morphSVG: {
            shape:
                "M1027.907-1153.083s39.208,27.1,71.624,27.218c24.337.086,37.374-12.544,70.737-26.355,30.262-12.527,72-27.5,97.015-27.541v-33.088H945.59l.041,32.516C965.8-1180.659,1027.907-1153.083,1027.907-1153.083Z",
            type: "linear"
        },
        ease: "Linear.none"
    });

    tl2.to(startShape, {
        duration: masterDuration,
        morphSVG:
            "M1013.907-1162.083c45.6,18.406,93.131,32.832,170.361.863,30.262-12.527,58-18.5,83.015-18.541v-33.088H945.59l.041,32.516C965.8-1180.659,989.109-1172.093,1013.907-1162.083Z"
    })
        .to($("#Group_9600"), {
            duration: 0.1,
            display: "none"
        })
        .to(startShape, {
            duration: masterDuration,
            morphSVG:
                "M1110.571-1114.459c24.654-2.479,35.263-21.1,28.9-38.645-4.67-12.868-19.016-30.4-25.173-36.518-2.117-2.1-7.268-.886-7.268-.886s-24.641,26.591-26.591,42.545C1077.421-1129,1092.493-1115.744,1110.571-1114.459Z"
        })

        .to($("#menu-trigger-shape"), {
            duration: 0.2,
            scale: 10
        })
        .to(startShape, {
            duration: masterDuration,
            morphSVG:
                "M2081.59,7.6c30.262-12.527-25.015-1021.959,0-1022v-208l-1914,9.555V7.6C187.759,7.268,2090.171,8.732,2081.59,7.6Z",
            scaleX: 10,
            scaleY: 10
        })
        .to($("body"), {
            overflow: "hidden"
        })
        .to($("#menu-popup"), {
            duration: masterDuration,
            opacity: 1,
            display: "block"
        });
    tl1.pause();
    tl2.pause();

    $("#menu-trigger-shape").on({
        mouseenter: function() {
            tl1.play();
        },
        mouseleave: function() {
            tl1.reverse();
        },
        click: function() {
            $(this).css("overflow", "visible");
            $(this).addClass("menu-opened");
            tl2.play();
            // $("body").addClass("overflow-hidden");
            $(".fixed-locator").fadeOut(1300);
        }
    });
    if ($(".paralax2").length) {
        gsap.to(".paralax2", {
            xPercent: -3,
            ease: "none",
            scrollTrigger: {
                trigger: ".bg-wrapper-hero",
                scrub: true,
                duration: "200%"
            }
        });
        gsap.to(".paralax4", {
            xPercent: 10,
            ease: "none",
            scrollTrigger: {
                trigger: ".bg-wrapper-hero",
                scrub: true,
                duration: "200%"
            }
        });
        gsap.to(".paralax5", {
            yPercent: 50,
            ease: "none",
            scrollTrigger: {
                trigger: ".bg-wrapper-hero",
                scrub: true,
                duration: "200%"
            }
        });
    }

    $(".menu-trigger").on("click", function() {
        $("body").addClass("overflow-hidden");
        $("#menu-popup").slideDown();
    });
    if ($(window).width() > 769) {
        // Fade up Animation Start
        const upAnimations = gsap.utils.toArray(".fade-up");
        upAnimations.forEach(animateUp => {
            gsap.from(animateUp, {
                scrollTrigger: {
                    trigger: animateUp,
                    toggleActions: "restart pause restart pause"
                    // onLeaveBack: self => self.disable()
                },
                opacity: 0,
                duration: 1,
                y: 150,
                stagger: 0.6
            });
        });
    }
    // var clicker = 0;
    // $(".sidebar-radio .radio").on("click", function() {
    //     $(".sidebar-radio .radio").removeClass("active");
    //     // $(this).toggleClass("active");
    //     if ($(this).hasClass("active")) {
    //         $(this).removeClass("active");
    //     } else {
    //         $(this).addClass("active");
    //     }
    //     clicker++;
    //     console.log("Radio Clicked: " + clicker);
    // });
    // Fade up Animation End

    // Story animations
    $("#story-animations").on("click", () => {
        var tractorTl = new TimelineMax();
        var ltrYoyo = new TimelineMax({ repeat: -1 });
        var truck = new TimelineMax({ repeat: -1 });
        var paths = new TimelineMax();
        tractorTl.to(".move-tractor", 3, { x: 250, ease: "none" });
        ltrYoyo.to(".yoyo-ltr", 2, { x: 20 }).to(".yoyo-ltr", 2, { x: 0 });
        truck.to("#Group9583", 0, { x: -400 }).to("#Group9583", 5, { x: 950 });

        function updateStroke() {
            $(".fill-path").css("transition", "1s");
            $(".fill-path").css("stroke-dasharray", "12");
        }
        const fillPath = gsap.utils.toArray(".fill-path");
        fillPath.forEach(fillPaths => {
            gsap.from(fillPaths, {
                scrollTrigger: {
                    trigger: fillPaths,
                    toggleActions: "restart pause restart pause"
                    // onLeaveBack: self => self.disable()
                },
                drawSVG: 0,
                duration: 2,
                ease: "power4.inOut"
            }).then(updateStroke);
        });
    });

    // Fade down Animation Start
    if ($(window).width() > 769) {
        const downAnimations = gsap.utils.toArray(".fade-down");
        downAnimations.forEach(animateUp => {
            gsap.from(animateUp, {
                scrollTrigger: {
                    trigger: animateUp,
                    toggleActions: "restart pause restart pause"
                    // onLeaveBack: self => self.disable()
                },
                opacity: 0,
                duration: 2,
                y: -150,
                stagger: 0.6
            });
        });
    }
    // Fade down Animation End
    // Fade ltr Animation Start
    if ($(window).width() > 769) {
        const ltrAnimations = gsap.utils.toArray(".fade-ltr");
        ltrAnimations.forEach(animateUp => {
            gsap.from(animateUp, {
                scrollTrigger: {
                    trigger: animateUp,
                    toggleActions: "restart pause restart pause"
                    // onLeaveBack: self => self.disable()
                },
                ease: "none",
                opacity: 0,
                duration: 1,
                x: -150,
                stagger: 0.6
            });
        });
    }
    // Fade ltr Animation End
    if ($(window).width() > 769) {
        // Fade rtl Animation start
        const rtlAnimations = gsap.utils.toArray(".fade-rtl");
        rtlAnimations.forEach(animateUp => {
            gsap.from(animateUp, {
                scrollTrigger: {
                    trigger: animateUp,
                    toggleActions: "restart pause restart pause"
                    // onLeaveBack: self => self.disable()
                },
                ease: "none",
                opacity: 0,
                duration: 1,
                x: 150,
                stagger: 0.6
            });
        });
    }
    // Fade rtl Animation end
    // Zoom in Animation start
    const scaleAnimations = gsap.utils.toArray(".scale-pop");
    scaleAnimations.forEach(animateUp => {
        gsap.from(animateUp, {
            scrollTrigger: {
                trigger: animateUp,
                toggleActions: "restart pause restart pause"
                // onLeaveBack: self => self.disable()
            },
            scale: 0,
            stagger: {
                each: 0.6,
                from: "center",
                grid: "auto"
            }
        });
    });
    // Zoom in Animation end

    // Zoom out Animation start
    const zoomOutAnimations = gsap.utils.toArray(".zoom-out");
    zoomOutAnimations.forEach(animateUp => {
        gsap.from(animateUp, {
            scrollTrigger: {
                trigger: animateUp,
                toggleActions: "restart pause restart pause"
                // onLeaveBack: self => self.disable()
            },
            scale: 2,
            duration: 1,
            stagger: 0.6
        });
    });
    // Zoom out Animation end
    // Gsap Animations End

    if ($(window).width() <= 425) {
        let activeBreadcrumb = $(".breadcrumb-item.color-red");
        activeBreadcrumb.prevAll().hide();
        activeBreadcrumb
            .prev()
            .find("a")
            .text("..");
        activeBreadcrumb.prev().show();
    }
    if ($(window).width() < 375) {
        let beadcrumbs = $(".breadcrumbs");
        beadcrumbs.hide();
    }

    /**
     * Request user Location to find nearest stores
     */
    if (!window.navigator.geolocation) {
        console.error("Navigator not supported by this browser.");
    } else {
        window.navigator.geolocation.getCurrentPosition(
            position => {
                window.user = {
                    coordinates: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                };

                let params = {
                    lat: position.coords.latitude,
                    lon: position.coords.longitude
                };

                let $mobileLocator = $(".mobile-locator");
                let $distance = $mobileLocator.find(".distance");
                let $storeName = $mobileLocator.find(".store-name");
                let $storeAddress = $mobileLocator.find(".store-address");

                axios.get("/api/stores", { params }).then(response => {
                    let closestStore = response.data.data[0];
                    let distance = parseFloat(closestStore.distance).toFixed(1);
                    $distance.text([distance, "km"].join(" "));
                    $storeName.text(closestStore.name);
                    $storeAddress.text(closestStore.address);
                    $mobileLocator.addClass("show");
                });
            },
            err => {
                console.error("Navigator Error", err);
            }
        );
    }

    $(".certs img").on("click", function() {
        var URL = $(this).attr("src");
        window.open(URL, "_blank");
    });
});

function initSlider() {
    $(".slick-slider:not(.slick-initialized)").slick();

    if ($(window).width() < 769) {
        $(".m-slider").slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            rows: 0,
            arrows: true,
            dots: false,
            centerMode: false,
            variableWidth: false,
            infinite: true,
            responsive: [
                {
                    breakpoint: 426,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }
}
