// Dynamic Modal Handler
$(document).on("click", '[data-toggle="dynamic-modal"]', function () {
    let btn = $(this);
    let action = $(this).data("action");
    let params = $(this).data("params");

    if (btn.hasClass('disabled')) return;
    btn.addClass('disabled');
    axios
        .get(`/modals/${action}`, {
            params
        })
        .then(response => {
            let modal = $(response.data);
            modal.one("hidden.bs.modal", function () {
                window.onModalShown = null;
                btn.removeClass('disabled', false);
                modal.remove();
            });

            $("body").append(modal);
            modal.modal("show");

        })
        .catch(error => {
            console.error("[Dynamic Modal] Error occured!", error);
            alert("Something went wrong :(");
        });
});
